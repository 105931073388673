import { Pencil2Icon } from '@radix-ui/react-icons'
import type { ColumnDef, PaginationState } from '@tanstack/react-table'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { RefreshCw } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'
import { StringParam, useQueryParam } from 'use-query-params'

import AddEditOrganizationModal from '@/components/admin/organization/AddEditOrganizationModal'
import DeleteOrganizationButtonWithConfirmation from '@/components/admin/organization/DeleteOrganizationButtonWithConfirmation'
import LinkUserModal from '@/components/admin/user/LinkUserModal'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import PaginatedTable from '@/components/v2/PaginatedTable'
import type { AdminOrganization } from '@/data/admin/organization'
import useAdminOrganization from '@/hooks/useAdminOrganization'
import useAdminOrganizations from '@/hooks/useAdminOrganizations'
import { cn } from '@/lib/utils'
import { dateWithTimeInIsoFormat } from '@/utils/dateHelpers'

export default function OrganizationsPage() {
  const { t } = useTranslation(['admin', 'common'])
  const [searchText, setSearchText] = useQueryParam('name', StringParam)
  const [name] = useDebounce(searchText, 500)

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })

  const { onDelete } = useAdminOrganization()
  const { data, isLoading, refetch, isRefetching } = useAdminOrganizations(
    undefined,
    name,
    pagination.pageSize,
    pagination.pageIndex,
    false,
  )
  const [orgId, setOrgId] = useQueryParam('organizationId', StringParam)
  const orgName = data?.organizations?.find(
    (org) => org.id === Number(orgId),
  )?.name

  const columns = useMemo<ColumnDef<AdminOrganization>[]>(() => {
    return [
      {
        id: 'actions',
        cell: ({ row }) => {
          const { id, name } = row.original

          return (
            <div className="flex gap-2">
              <Button
                variant="outline"
                type="button"
                className="h-8 w-8 rounded-full border-yellow-700/50 p-0 text-yellow-700 hover:bg-yellow-50 hover:text-yellow-800"
                onClick={() => setOrgId(id.toString())}
              >
                <span className="sr-only">Edit organization</span>
                <Pencil2Icon className="h-4 w-4" />
              </Button>
              <DeleteOrganizationButtonWithConfirmation
                onDelete={async () => onDelete(id)}
                name={name}
              />
            </div>
          )
        },
      },
      {
        accessorKey: 'logoUrl',
        header: t('organizations.logoUrl.header'),
        cell: ({ row }) => {
          const { logoUrl } = row.original

          if (!logoUrl) return null

          return <img alt="logo" className="max-h-8" src={logoUrl} />
        },
      },
      {
        accessorKey: 'name',
        header: t('organizations.name.header'),
      },
      {
        accessorKey: 'type',
        header: t('organizations.type.header'),
      },
      {
        accessorKey: 'language',
        header: t('organizations.language.header'),
        cell: ({ row }) => {
          const { language } = row.original

          if (!language) return null
          return language === 'sv'
            ? t('organizations.language.swedish')
            : language === 'en'
              ? t('organizations.language.english')
              : ''
        },
      },
      {
        accessorKey: 'distanceUnit',
        header: t('organizations.distanceUnit.header'),
        cell: ({ row }) => {
          const { distanceUnit } = row.original

          if (!distanceUnit) return null
          return distanceUnit.toLowerCase() === 'meter'
            ? 'm'
            : distanceUnit.toLowerCase() === 'feet'
              ? 'ft'
              : ''
        },
      },
      {
        accessorKey: 'temperatureUnit',
        header: t('organizations.temperatureUnit.header'),
        cell: ({ row }) => {
          const { temperatureUnit } = row.original

          if (!temperatureUnit) return null
          return temperatureUnit.toLowerCase() === 'celsius'
            ? '°C'
            : temperatureUnit.toLowerCase() === 'fahrenheit'
              ? '°F'
              : ''
        },
      },
      {
        accessorKey: 'eligibleForBetaFirmwareVersions',
        header: t('organizations.eligible_for_beta_firmware_versions.header'),
        cell: ({ row }) => {
          const { eligibleForBetaFirmwareVersions = false } = row.original
          return eligibleForBetaFirmwareVersions.toString()
        },
      },
      {
        accessorKey: 'users',
        header: t('organizations.users.header'),
        cell: ({ row }) => {
          const { organizationUsers } = row.original

          if (!organizationUsers) return null
          return organizationUsers.length
        },
      },
      {
        accessorKey: 'website',
        header: t('organizations.website.header'),
      },
      {
        accessorKey: 'headerImageUrl',
        header: t('organizations.headerImageUrl.header'),
        cell: ({ row }) => {
          const { headerImageUrl } = row.original

          if (!headerImageUrl) return null
          return <img alt="header" className="max-h-8" src={headerImageUrl} />
        },
      },
      {
        accessorKey: 'updatedAt',
        header: t('organizations.updatedAt.header'),
        cell: ({ row }) => {
          const { updatedAt } = row.original

          if (!updatedAt) return null
          return (
            <span className="whitespace-nowrap">
              {dateWithTimeInIsoFormat(new Date(updatedAt))}
            </span>
          )
        },
      },
      {
        accessorKey: 'createdAt',
        header: t('organizations.createdAt.header'),
        cell: ({ row }) => {
          const { createdAt } = row.original

          if (!createdAt) return null
          return (
            <span className="whitespace-nowrap">
              {dateWithTimeInIsoFormat(new Date(createdAt))}
            </span>
          )
        },
      },
    ]
  }, [])

  const table = useReactTable({
    data: data ? data.organizations || [] : [],
    columns,
    pageCount: data?.totalCount
      ? Math.ceil(data.totalCount / pagination.pageSize)
      : -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <div>
      <div className="px-4">
        <div className="relative my-8">
          <h2 className="text-3xl">Organizations</h2>
          <div className="absolute right-0 top-0">
            <Button
              variant="outline"
              onClick={async () => refetch()}
              disabled={isRefetching}
            >
              <RefreshCw
                className={cn('h-4 w-4', isRefetching && 'animate-spin')}
              />
              <span className="pl-2 text-base">Refresh</span>
            </Button>
          </div>
        </div>
        <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap">
          <Input
            defaultValue={searchText ?? ''}
            onChange={(e) => setSearchText(e.currentTarget.value)}
            placeholder="Search by name..."
            className="max-w-sm"
          />
          <div className="flex flex-1 justify-end">
            <Button
              type="button"
              variant="outline"
              size="icon"
              onClick={() => setOrgId('new')}
            >
              +
            </Button>
          </div>
        </div>
        <PaginatedTable<AdminOrganization>
          table={table}
          isLoading={isLoading}
        />
      </div>
      <AddEditOrganizationModal />
      <LinkUserModal organizationId={orgId} organizationName={orgName} />
    </div>
  )
}
