import type {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DataTableFooterAction, DataTablePagination } from '@/components/admin/DataTablePagination'
import DataTableSearch from '@/components/admin/DataTableSearch'
import { DataTableViewOptions } from '@/components/admin/DataTableViewOptions'
import { Skeleton } from '@/components/ui/skeleton'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { useBrowserStorage } from '@/hooks/useBrowserStorage'

import { Button } from '../ui/button'
import { DownloadIcon } from '@radix-ui/react-icons'
import { exportCsv } from '@/utils/exportCsv'
import { cn } from '@/lib/utils'
import { useToast } from '../ui/use-toast'
import { failureToastClassNames } from '@/utils/toastHelpers'

interface DataTableCsvConfig<TData> {
  filename?: string
  includeFilters?: boolean
  overrides?: {
    [K in keyof TData]?: (value: TData[K]) => string
  }
}

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  filterColumn: string
  entityName: string
  onCreateClick?: () => void
  isLoading?: boolean
  hideHeader?: boolean
  compact?: boolean
  csvExport?: DataTableCsvConfig<TData>
}

const SKELETON_ROW_COUNT = 10

export function DataTable<TData, TValue>({
  columns,
  data,
  filterColumn,
  entityName,
  onCreateClick,
  isLoading = false,
  hideHeader = false,
  compact = false,
  csvExport,
}: DataTableProps<TData, TValue>) {
  const { toast } = useToast()
  const { t } = useTranslation(['admin'])
  const [sorting, setSorting] = useBrowserStorage<SortingState>(
    `${entityName}_sorting`,
    [],
    'localStorage',
  )
  const [columnVisibility, setColumnVisibility] =
    useBrowserStorage<VisibilityState>(
      `${entityName}_visibility`,
      {},
      'localStorage',
    )

  // @TODO figure out how to add this to queryparam. Array of objects
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
      columnVisibility,
    },
  })

  const handleExportCsv = () => {
    const visibleColumns = table.getAllColumns()
      .filter(column => column.getIsVisible())

    const headers = visibleColumns.reduce((acc, column) => ({
      ...acc,
      [column.id]: column.columnDef.header as string
    }), {})

    const exportData = table.getFilteredRowModel().rows.map(row => {
      const rowData: Record<string, any> = {}
      visibleColumns.forEach(column => {
        const columnId = column.id as keyof TData
        const value = row.getValue(column.id) as TData[keyof TData]

        if (csvExport?.overrides?.[columnId]) {
          rowData[column.id] = csvExport.overrides[columnId]!(value)
        } else {
          rowData[column.id] = value
        }
      })
      return rowData
    })

    try {
      exportCsv({
        data: exportData,
        headers,
        filename: csvExport?.filename
      })
    } catch (e) {
      toast({
        title: `Failed to export CSV, Please try again or contact support if the issue persists.`,
        className: failureToastClassNames,
      })
    }
  }

  // Create footer actions array
  const tableFooterActions = useMemo(() => {
    const actions: DataTableFooterAction[] = []

    if (csvExport) {
      actions.push({
        icon: <DownloadIcon className="h-4 w-4" />,
        label: t('data_table.export_csv'),
        onClick: handleExportCsv
      })
    }

    return actions
  }, [csvExport])

  return (
    <div>
      {hideHeader ? null : (
        <div className="flex items-center justify-between gap-2 py-4">
          <div className="flex-1">
            <DataTableSearch table={table} filterColumn={filterColumn} />
          </div>
          <div className="flex gap-2">
            <DataTableViewOptions table={table} />
            {typeof onCreateClick !== 'undefined' ? (
              <Button
                type="button"
                variant="outline"
                size="icon"
                onClick={onCreateClick}
              >
                +
              </Button>
            ) : null}
          </div>
        </div>
      )}
      <div className="rounded-md border">
        <Table className={compact ? "[@media(min-width:640px)]:text-sm" : ""}>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      className={cn("whitespace-nowrap", compact && "py-2")}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading ? (
              Array.from({ length: SKELETON_ROW_COUNT }).map((_, i) => (
                <TableRow className="odd:bg-sky-50 hover:bg-sky-100" key={i}>
                  {columns.map((_, k) => (
                    <TableCell key={k}>
                      <Skeleton className="h-2 w-full" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  className="odd:bg-sky-50 hover:bg-sky-100"
                  key={row.id}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className={compact ? "py-2" : ""}
                    >
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t('data_table.no_results.text')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination
        table={table}
        compact={compact}
        footerActions={tableFooterActions}
      />
    </div>
  )
}
