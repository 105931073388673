import type { ColumnDef, PaginationState } from '@tanstack/react-table'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { RefreshCw } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTimeParam, StringParam, useQueryParam } from 'use-query-params'

import OrganizationNameAutocomplete from '@/components/admin/OrganizationNameAutocomplete'
import { Button } from '@/components/ui/button'
import PaginatedTable from '@/components/v2/PaginatedTable'
import DeviceImeiAutocomplete from '@/components/v2/selects/DeviceImeiAutocomplete'
import TableDateSelect from '@/components/v2/selects/TableDateSelect'
import type { MetadataPoint } from '@/data/admin/metadataPoint'
import useAdminMetadataPoints from '@/hooks/useAdminMetadataPoints'
import { cn } from '@/lib/utils'
import { dateWithTimeInIsoFormat } from '@/utils/dateHelpers'

export default function MetadataPage() {
  const { t } = useTranslation(['admin'])
  const [searchText, setSearchText] = useQueryParam('imei', StringParam)

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })

  const [orgId, setOrgId] = useQueryParam('orgId', StringParam)
  const [from] = useQueryParam('from', DateTimeParam)
  const [to] = useQueryParam('to', DateTimeParam)

  const { data, isLoading, refetch, isRefetching } = useAdminMetadataPoints(
    searchText,
    pagination.pageSize,
    pagination.pageIndex,
    from ?? undefined,
    to ?? undefined,
    orgId,
  )

  const columns = useMemo<ColumnDef<MetadataPoint>[]>(() => {
    return [
      {
        accessorKey: 'timestamp',
        header: t('metadata_points.timestamp'),
        cell: ({ row }) => {
          const { createdAt } = row.original

          if (!createdAt) return null

          return (
            <span className="whitespace-nowrap">
              {dateWithTimeInIsoFormat(new Date(createdAt))}
            </span>
          )
        },
      },
      {
        accessorKey: 'imei',
        header: t('metadata_points.imei'),
      },
      {
        accessorKey: 'justBooted',
        header: t('metadata_points.just_booted'),
      },
      {
        accessorKey: 'resetReason',
        header: t('metadata_points.reset_reason'),
      },
      {
        accessorKey: 'uptime',
        header: t('metadata_points.uptime'),
      },
      {
        accessorKey: 'firmwareVersion',
        header: t('metadata_points.firmware_version'),
      },
      {
        accessorKey: 'cycleTime',
        header: t('metadata_points.cycle_time'),
      },
      {
        accessorKey: 'modemFwVersion',
        header: t('metadata_points.modem_fw_version'),
      },
      {
        accessorKey: 'referenceSignal',
        header: t('metadata_points.reference_signal'),
      },
      {
        accessorKey: 'errorStatus',
        header: t('metadata_points.error_status'),
      },
    ]
  }, [])

  const table = useReactTable({
    data: data ? data.metadataPoints || [] : [],
    columns,
    pageCount: data?.totalCount
      ? Math.ceil(data.totalCount / pagination.pageSize)
      : -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <div>
      <div className="relative my-8">
        <h2 className="text-3xl">{t('Metadata')}</h2>
        <div className="absolute right-0 top-0">
          <Button
            variant="outline"
            onClick={async () => refetch()}
            disabled={isRefetching}
          >
            <RefreshCw
              className={cn('h-4 w-4', isRefetching && 'animate-spin')}
            />
            <span className="hidden pl-2 text-base md:block">Refresh</span>
          </Button>
        </div>
      </div>
      <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap">
        <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap">
          <div className="min-w-full sm:min-w-[200px]">
            <OrganizationNameAutocomplete
              onSelect={(val) => setOrgId(val)}
              value={orgId}
            />
          </div>
          <div className="min-w-full sm:min-w-[200px]">
            <DeviceImeiAutocomplete
              orgId={orgId}
              imei={searchText || ''}
              onSelect={(val) => setSearchText(val)}
            />
          </div>
          <div className="min-w-full sm:min-w-[200px]">
            <TableDateSelect />
          </div>
        </div>
      </div>
      <PaginatedTable<MetadataPoint> table={table} isLoading={isLoading} />
    </div>
  )
}
