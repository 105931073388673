import {
  addDays,
  differenceInCalendarDays,
  isAfter
} from 'date-fns'
import { useMemo, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTimeParam, withDefault } from 'use-query-params'
import { useQueryParams } from 'use-query-params'
import { ColumnDef } from '@tanstack/react-table'

import useDeviceData from '@/hooks/useDeviceData'
import { RawData } from '@/data/device-data'
import { dateWithTimeInIsoFormat } from '@/utils/dateHelpers'
import { Card, CardProps } from '../ui/card'
import { DataTable } from '../admin/DataTable'
import DeviceDataChart from './DeviceDataChart'

const DEFAULT_FROM_DATE = addDays(new Date(), -1)
const DEFAULT_TO_DATE = new Date(new Date().setHours(23, 59, 59, 999))

function DeviceData({
  cardColor,
  deviceId,
  materialHeightTop,
  materialHeightBottom,
  distanceUnitSuffix,
  tempUnitSuffix,
  leftSectionAlias,
  rightSectionAlias,
}: {
  cardColor: CardProps['variant']
  deviceId: string
  materialHeightTop?: number
  materialHeightBottom?: number
  distanceUnitSuffix: 'in' | 'mm' 
  tempUnitSuffix: '°C' | '°F'
  leftSectionAlias: string
  rightSectionAlias: string
}) {
  const { t } = useTranslation(['app'])
  const [date] = useQueryParams({
    from: withDefault(DateTimeParam, DEFAULT_FROM_DATE),
    to: withDefault(DateTimeParam, DEFAULT_TO_DATE)
  }, {
    removeDefaultsFromUrl: false
  })

  // Determine if we're showing real-time data
  const isLiveMode = useMemo(() => isAfter(date.to, new Date()), [date.to])

  /**
   * Data fetching strategy:
   * 1. First fetch: Get historical data for the full date range
   * 2. Live updates: Only fetch new data since the last known timestamp
   * 3. Combine both datasets for display
   */

  // Step 1: Fetch historical data
  const { deviceData: historicalData, isLoading: isLoadingHistorical } = useDeviceData(
    deviceId,
    date.from,
    date.to,
    true // disable polling for historical fetch // TODO, get it once every hour?
  )

  // Step 2: Get the most recent timestamp to use as starting point for live updates
  const mostRecentTimestamp = useMemo(() => {
    if (!historicalData?.rawData?.[0]) return null
    return new Date(Number(historicalData.rawData[0].timestamp + 1) * 1000)
  }, [historicalData?.rawData])

  // Step 3: Fetch live updates (only if in live mode and we have historical data)
  const { deviceData: liveData, isLoading: isLoadingLive } = useDeviceData(
    deviceId,
    mostRecentTimestamp ?? date.from,
    DEFAULT_TO_DATE,
    !isLiveMode
  )

  // Step 4: Merge historical and live data
  const deviceData = useMemo(() => {
    if (!historicalData) return undefined
    if (!liveData) return historicalData
    return {
      ...historicalData,
      rawData: [...(liveData.rawData ?? []), ...(historicalData.rawData ?? [])]
    }
  }, [historicalData, liveData])

  const isLoading = isLoadingHistorical || isLoadingLive

  const showTimeOnly =
    date.from &&
    date.to &&
    Math.abs(differenceInCalendarDays(date.from, date.to)) <= 1

  const showCondensed =
    !showTimeOnly &&
    date.from &&
    date.to &&
    Math.abs(differenceInCalendarDays(date.from, date.to)) <= 14


  const columns = useMemo<ColumnDef<RawData>[]>(() => {
    return [
      {
        accessorKey: 'timestamp',
        header: t('device_data_points.timestamp'),
        cell: ({ row }) => {
          const { timestamp } = row.original
          if (!timestamp) return null
          return (
            <span className="whitespace-nowrap">
              {dateWithTimeInIsoFormat(new Date(Number(timestamp) * 1000))}
            </span>
          )
        },
      },
      {
        accessorKey: 'waterLevelLeft',
        header: (leftSectionAlias ? t('device_data_points.water_level') + ' ' + leftSectionAlias : t('device_data_points.water_level_left')) + ` (${distanceUnitSuffix})`,
      },
      {
        accessorKey: 'waterLevelRight',
        header: (rightSectionAlias ? t('device_data_points.water_level') + ' ' + rightSectionAlias : t('device_data_points.water_level_right')) + ` (${distanceUnitSuffix})`,
      },
      {
        accessorKey: 'fillValveTime',
        header: t('device_data_points.fill_valve_time') + ` (s)`,
      },
      ...(deviceData?.hasMoistureSensor ? [
        {
          accessorKey: 'moistureLeft',
          header: (leftSectionAlias ? t('device_data_points.moisture') + ' ' + leftSectionAlias : t('device_data_points.moisture_left')) + ` (%)`,
        },
        {
          accessorKey: 'moistureRight',
          header: (rightSectionAlias ? t('device_data_points.moisture') + ' ' + rightSectionAlias : t('device_data_points.moisture_right')) + ` (%)`,
        },
        {
          accessorKey: 'temperatureLeft',
          header: (leftSectionAlias ? t('device_data_points.temperature') + ' ' + leftSectionAlias : t('device_data_points.temperature_left')) + ` (${tempUnitSuffix})`,
        },
        {
          accessorKey: 'temperatureRight',
          header: (rightSectionAlias ? t('device_data_points.temperature') + ' ' + rightSectionAlias : t('device_data_points.temperature_right')) + ` (${tempUnitSuffix})`,
        }
      ] : []),
    ]
  }, [deviceData?.hasMoistureSensor, leftSectionAlias, rightSectionAlias])

  return (
    <>
      <Card variant={cardColor}>
        <DeviceDataChart
          deviceData={deviceData}
          isLoading={isLoading}
          showTimeOnly={showTimeOnly}
          showCondensed={showCondensed}
          materialHeightTop={materialHeightTop}
          materialHeightBottom={materialHeightBottom}
          distanceUnitSuffix={distanceUnitSuffix}
          tempUnitSuffix={tempUnitSuffix}
          leftSectionAlias={leftSectionAlias}
          rightSectionAlias={rightSectionAlias}
          defaultToDate={DEFAULT_TO_DATE}
          defaultFromDate={DEFAULT_FROM_DATE}
        />
        <div className="p-4">
          <div className="bg-white rounded-md">
            <DataTable
              columns={columns}
              data={deviceData?.rawData ?? []}
              isLoading={isLoading}
              entityName="test"
              filterColumn="timestamp"
              hideHeader={true}
              compact={true}
              csvExport={{
                filename: `device-data-${deviceId}`,
                overrides: {
                  timestamp: (value: string) => dateWithTimeInIsoFormat(new Date(Number(value) * 1000))
                }
              }}
            />
          </div>
        </div>
      </Card>
    </>
  )
}
export default DeviceData
