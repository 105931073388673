interface ExportCsvConfig<T> {
    data: T[]
    headers?: Record<keyof T, string>
    filename?: string
}

export const exportCsv = <T extends Record<string, any>>({
    data,
    headers,
    filename,
}: ExportCsvConfig<T>): void => {
    if (!data.length) return

    const headerRow = headers || Object.keys(data[0])
    const csvRows = [
        Object.values(headerRow).join(','),
        ...data.map(row =>
            Object.keys(data[0])
                .map(key => JSON.stringify(row[key]))
                .join(',')
        ),
    ]

    const csvString = csvRows.join('\n')
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)

    const date = new Date().toISOString().split('T')[0]
    link.setAttribute('href', url)
    link.setAttribute('download', `${filename || `export-csv-${date}`}.csv`)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
} 