import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from '@radix-ui/react-icons'
import type { Table } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'

export interface DataTableFooterAction {
  icon: React.ReactNode
  label: string
  onClick: () => void
  disabled?: boolean
  className?: string
}
interface DataTablePaginationProps<TData> {
  table: Table<TData>
  compact?: boolean
  footerActions?: DataTableFooterAction[]
}

export function DataTablePagination<TData>({
  table,
  compact = false,
  footerActions = [],
}: DataTablePaginationProps<TData>) {
  const { t } = useTranslation(['admin'])

  const maxPageIndex = table.getPageCount()

  return (
    <div className={cn('flex items-center justify-between px-4 py-4', compact && 'py-2')}>
      <div className="flex items-center space-x-6 lg:space-x-8">
        <div className={cn('flex items-center space-x-2', compact && 'hidden sm:flex')}>
          <p className="text-sm font-medium">{t('data_table.rows_per_page')}</p>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value) => {
              table.setPageSize(Number(value))
            }}
          >
            <SelectTrigger className={cn('w-[70px] h-8', compact && 'h-7')}>
              <SelectValue placeholder={table.getState().pagination.pageSize} />
            </SelectTrigger>
            <SelectContent side="top">
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className={cn('flex items-center justify-center text-sm font-medium w-[100px]', compact && 'w-auto')}>
          {t('data_table.current_page', {
            currentPage: table.getState().pagination.pageIndex + 1,
            pageCount: maxPageIndex,
          })}
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            className={cn('hidden h-8 w-8 p-0 lg:flex', compact && 'md:flex')}
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to first page</span>
            <DoubleArrowLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className={cn('h-8 w-8 p-0', compact && 'h-7')}
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className={cn('h-8 w-8 p-0', compact && 'h-7')}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className={cn('hidden h-8 w-8 p-0 lg:flex', compact && 'md:flex')}
            onClick={() => table.setPageIndex(maxPageIndex - 1)}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to last page</span>
            <DoubleArrowRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
      {footerActions.length > 0 ? (
        <div className="flex items-center space-x-2">
          {footerActions.map((action, index) => (
            <Button
              key={index}
              variant="outline"
              className={cn('h-8', action.className, compact && 'h-7')}
              onClick={action.onClick}
              disabled={action.disabled}
            >
              {action.icon}
              <span className="ml-2 hidden sm:inline">{action.label}</span>
            </Button>
          ))}
        </div>
      ) : null}
    </div>
  )
}
