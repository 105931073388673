import {
  addDays
} from 'date-fns'
import { useState } from 'react'

import Waterdrop from '@/assets/illustrations/waterdrop.svg?react'
import type { ChartData, DeviceData } from '@/data/device-data'

import { Button } from '../ui/button'
import { ScrollArea, ScrollBar } from '../ui/scroll-area'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs'

import ECChart from './charts/ECChart'
import MoistureChart from './charts/MoistureChart'
import TempChart from './charts/TempChart'
import WaterLevelChart from './charts/WaterLevelChart'
import TableDateSelect from './selects/TableDateSelect'

const MIN_ZOOM = 5
const DEFAULT_ZOOM: {
  x1: string | null
  x2: string | null
} = {
  x1: null,
  x2: null,
}

type TabType = 'waterlevels' | 'temperature' | 'ec' | 'moisture'

function DeviceDataChart({
  deviceData,
  isLoading,
  materialHeightTop,
  materialHeightBottom,
  distanceUnitSuffix,
  tempUnitSuffix,
  leftSectionAlias,
  rightSectionAlias,
  showTimeOnly,
  showCondensed,
  defaultFromDate,
  defaultToDate,
}: {
  deviceData?: DeviceData
  isLoading: boolean
  materialHeightTop?: number
  materialHeightBottom?: number
  distanceUnitSuffix: 'in' | 'mm'
  tempUnitSuffix: '°C' | '°F'
  leftSectionAlias: string
  rightSectionAlias: string
  showTimeOnly: boolean
  showCondensed: boolean
  defaultFromDate: Date
  defaultToDate: Date
}) {
  const [activeTab, setActiveTab] = useState<TabType>('waterlevels')

  const [filteredData, setFilteredData] = useState<ChartData[] | null>(null)
  const [zoomArea, setZoomArea] = useState(DEFAULT_ZOOM)
  const [isZooming, setIsZooming] = useState(false)

  const { chartData, hasMoistureSensor } = deviceData || {}

  const isZoomed =
    !isLoading &&
    filteredData &&
    filteredData.length > 0 &&
    filteredData?.length !== chartData?.length

  const showZoomBox =
    isZooming &&
    zoomArea.x1 &&
    zoomArea.x2 &&
    !(Math.abs(Number(zoomArea.x1) - Number(zoomArea.x2)) < MIN_ZOOM)

  function handleZoomOut() {
    setFilteredData(null)
    setZoomArea(DEFAULT_ZOOM)
  }

  // @ts-expect-error no event type export from recharts
  function handleMouseDown(e) {
    setIsZooming(true)
    const { activeLabel = '' } = e || {}
    setZoomArea({
      x1: activeLabel as string,
      x2: activeLabel as string,
    })
  }

  // @ts-expect-error no event type export from recharts
  function handleMouseMove(e) {
    if (isZooming) {
      setZoomArea((prev) => ({
        ...prev,
        x2: e.activeLabel,
      }))
    }
  }

  function handleMouseUp() {
    if (isZooming) {
      setIsZooming(false)
      let { x1, x2 } = zoomArea

      if (!x1 || !x2) return

      if (x1 > x2) [x1, x2] = [x2, x1]
      // @ts-expect-error fix types here
      if (x2 - x1 < MIN_ZOOM) return

      const dataPointsInRange = (filteredData || chartData)?.filter(
        (d) => d.timestamp >= x1 && d.timestamp <= x2,
      )

      if (typeof dataPointsInRange !== 'undefined') {
        setFilteredData(dataPointsInRange)
      }

      setZoomArea(DEFAULT_ZOOM)
    }
  }

  return (
    <div className="relative">
      <div className="m-4 flex gap-4">
        <TableDateSelect
          defaultFrom={defaultFromDate}
          defaultTo={defaultToDate}
          useLongTimeRanges={true}
          hideTimeForFutureDates={true}
        />
        {isZoomed ? (
          <Button variant="outline" onClick={handleZoomOut}>
            Reset zoom
          </Button>
        ) : null}
      </div>
      {isLoading && !chartData ? (
        <div className="flex h-full min-h-[300px] items-center justify-center">
          <Waterdrop className="h-12 w-12 animate-bounce delay-75" />
          <Waterdrop className="h-10 w-10 animate-bounce delay-100" />
          <Waterdrop className="h-8 w-8 animate-bounce delay-150" />
        </div>
      ) : (
        <>
          {hasMoistureSensor ? (
            <Tabs
              className="m-4 rounded-full"
              value={activeTab}
              onValueChange={(val) => setActiveTab(val as TabType)}
            >
              <ScrollArea>
                <TabsList>
                  <TabsTrigger className="bg-sand" value="waterlevels">
                    Water Levels
                  </TabsTrigger>
                  <TabsTrigger value="moisture">Moisture</TabsTrigger>
                  <TabsTrigger value="ec">Electrical Conductivity</TabsTrigger>
                  <TabsTrigger value="temperature">Temperature</TabsTrigger>
                </TabsList>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>
              <TabsContent value="waterlevels">
                <WaterLevelChart
                  data={filteredData || chartData || []}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  isZoomed={Boolean(isZoomed)}
                  showTimeOnly={Boolean(showTimeOnly)}
                  showCondensed={Boolean(showCondensed)}
                  unitSuffix={distanceUnitSuffix}
                  zoomArea={zoomArea}
                  showZoomBox={Boolean(showZoomBox)}
                  materialHeightTop={materialHeightTop}
                  materialHeightBottom={materialHeightBottom}
                  leftSectionAlias={leftSectionAlias}
                  rightSectionAlias={rightSectionAlias}
                />
              </TabsContent>
              <TabsContent value="moisture">
                <MoistureChart
                  data={filteredData || chartData || []}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  isZoomed={Boolean(isZoomed)}
                  showTimeOnly={Boolean(showTimeOnly)}
                  showCondensed={Boolean(showCondensed)}
                  unitSuffix={'%'}
                  zoomArea={zoomArea}
                  showZoomBox={Boolean(showZoomBox)}
                  leftSectionAlias={leftSectionAlias}
                  rightSectionAlias={rightSectionAlias}
                />
              </TabsContent>
              <TabsContent value="ec">
                <ECChart
                  data={filteredData || chartData || []}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  isZoomed={Boolean(isZoomed)}
                  showTimeOnly={Boolean(showTimeOnly)}
                  showCondensed={Boolean(showCondensed)}
                  unitSuffix={'μS/cm'}
                  zoomArea={zoomArea}
                  showZoomBox={Boolean(showZoomBox)}
                  leftSectionAlias={leftSectionAlias}
                  rightSectionAlias={rightSectionAlias}
                />
              </TabsContent>
              <TabsContent value="temperature">
                <TempChart
                  data={filteredData || chartData || []}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  isZoomed={Boolean(isZoomed)}
                  showTimeOnly={Boolean(showTimeOnly)}
                  showCondensed={Boolean(showCondensed)}
                  unitSuffix={tempUnitSuffix}
                  zoomArea={zoomArea}
                  showZoomBox={Boolean(showZoomBox)}
                  leftSectionAlias={leftSectionAlias}
                  rightSectionAlias={rightSectionAlias}
                />
              </TabsContent>
            </Tabs>
          ) : (
            <WaterLevelChart
              data={filteredData || chartData || []}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              isZoomed={Boolean(isZoomed)}
              showTimeOnly={Boolean(showTimeOnly)}
              showCondensed={Boolean(showCondensed)}
              unitSuffix={distanceUnitSuffix}
              zoomArea={zoomArea}
              showZoomBox={Boolean(showZoomBox)}
              materialHeightTop={materialHeightTop}
              materialHeightBottom={materialHeightBottom}
              leftSectionAlias={leftSectionAlias}
              rightSectionAlias={rightSectionAlias}
            />
          )}
        </>
      )}
    </div>
  )
}
export default DeviceDataChart
