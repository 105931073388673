import { SearchIcon } from 'lucide-react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useParams } from 'react-router-dom'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import FlowTrackLogo from '@/assets/flowtracklogo.svg?react'
import { Input } from '@/components/ui/input'
import { useAuth } from '@/components/v2/AuthProvider'
import MobileMenu from '@/components/v2/menus/MobileMenu'
import NotificationMenu from '@/components/v2/menus/NotificationMenu'
import UserMenu from '@/components/v2/menus/UserMenu'
import WeatherMenu from '@/components/v2/menus/WeatherMenu'
import type { UserRole } from '@/data/admin/user'
import { isOrgAdminOrAdmin } from '@/utils/roleHelpers'
import { capitalizeFirstCharacter } from '@/utils/textHelpers'

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '../ui/navigation-menu'

import { useOrg } from './OrgProvider'

export interface Route {
  key?: string
  name: string
  path: string
}

function HeaderBar() {
  const inputRef = useRef<HTMLInputElement>(null)
  const [searchText, setSearchText] = useQueryParam(
    'q',
    withDefault(StringParam, ''),
  )
  const { t } = useTranslation(['common', 'admin'])
  const { user } = useAuth()
  const location = useLocation()
  const { selectedOrgId } = useOrg()
  const { orgId } = useParams()

  const showAdminRoutes = isOrgAdminOrAdmin(user?.role as UserRole | undefined)
  const routes: Route[] = [
    {
      key: 'dashboard',
      path: `/app/${selectedOrgId}`,
      name: t('dashboard'),
    },
    {
      path: `/app/${selectedOrgId}/activity`,
      name: t('activity'),
    },
    {
      path: `/app/${selectedOrgId}/settings`,
      name: t('settings'),
    },
  ]

  const adminRoutes: Route[] = showAdminRoutes
    ? [
        {
          path: `/admin/users`,
          name: t('users'),
        },
        {
          path: `/admin/organizations`,
          name: t('organizations'),
        },
        {
          path: `/admin/devices`,
          name: t('devices', { ns: 'admin' }),
        },
        {
          path: `/admin/data`,
          name: t('data', { ns: 'admin' }),
        },
        {
          path: `/admin/metadata`,
          name: t('metadata', { ns: 'admin' }),
        },
        {
          path: `/admin/data-downlinks`,
          name: t('data_downlinks', { ns: 'admin' }),
        },
        {
          path: `/admin/metadata-downlinks`,
          name: t('metadata_downlinks', { ns: 'admin' }),
        },
        {
          path: `/admin/firmware`,
          name: t('firmware', { ns: 'admin' }),
        },
      ]
    : []

  const isActiveRoute = (path: string) => {
    return location.pathname === path
  }

  return (
    <div className="flex h-14 items-center justify-between bg-white px-4 md:bg-sand md:px-0 md:pr-4">
      <MobileMenu routes={routes} adminRoutes={adminRoutes} />

      <div className="flex h-full items-center gap-4">
        <Link to={routes.find((r) => r.key === 'dashboard')?.path || '/'}>
          <FlowTrackLogo />
        </Link>
        <div className="hidden h-full gap-4 md:flex">
          {routes.map((route) => (
            <Link
              to={route.path}
              key={route.path}
              className={`flex h-full items-center rounded-lg px-2 ${
                isActiveRoute(route.path)
                  ? 'font-semibold'
                  : 'hover:bg-darkSand/70'
              }`}
            >
              {capitalizeFirstCharacter(route.name)}
            </Link>
          ))}
          {adminRoutes.length > 0 ? (
            <NavigationMenu className="h-full">
              <NavigationMenuList className="h-full">
                <NavigationMenuItem>
                  <NavigationMenuTrigger className="bg-transparent text-base">
                    Admin
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <div className="flex flex-col space-y-2">
                      {adminRoutes.map((route) => (
                        <NavigationMenuLink
                          key={route.path}
                          asChild
                          className="py-4 hover:bg-darkSand/70"
                        >
                          <Link
                            to={route.path}
                            key={route.path}
                            className={`whitespace-nowrap px-8 ${
                              isActiveRoute(route.path) ? 'font-semibold' : ''
                            }`}
                          >
                            {capitalizeFirstCharacter(route.name)}
                          </Link>
                        </NavigationMenuLink>
                      ))}
                    </div>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              </NavigationMenuList>
            </NavigationMenu>
          ) : null}
        </div>
      </div>
      <div className="flex items-center gap-4">
        {orgId ? (
          <>
            <div className="hidden lg:block">
              <WeatherMenu />
            </div>
            <div className="hidden lg:block">
              <div className="relative">
                <SearchIcon
                  className="absolute left-2 top-3 h-4 w-4"
                  onClick={() => inputRef?.current?.focus()}
                />
                <Input
                  className="bg-darkSand pl-8"
                  type="text"
                  placeholder={capitalizeFirstCharacter(t('search'))}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  ref={inputRef}
                />
              </div>
            </div>
          </>
        ) : null}
        <NotificationMenu />
        <UserMenu />
      </div>
    </div>
  )
}
export default HeaderBar
