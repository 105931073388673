import { Pencil2Icon } from '@radix-ui/react-icons'
import type { ColumnDef, PaginationState } from '@tanstack/react-table'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { RefreshCw } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParam } from 'use-query-params'

import AddEditDeviceModal from '@/components/admin/AddEditDeviceModal'
import { DataTablePagination } from '@/components/admin/DataTablePagination'
import DeleteDeviceButton from '@/components/admin/DeleteDeviceButton'
import OrganizationNameAutocomplete from '@/components/admin/OrganizationNameAutocomplete'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Skeleton } from '@/components/ui/skeleton'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import type { AdminListDevice } from '@/data/admin/device'
import useAdminDevices from '@/hooks/useAdminDevices'
import { cn } from '@/lib/utils'
import { dateWithTimeInIsoFormat } from '@/utils/dateHelpers'

export default function AdminDevicesPage() {
  const { t } = useTranslation(['admin'])
  const [, setDeviceId] = useQueryParam('deviceId', StringParam)
  const [searchText, setSearchText] = useQueryParam('imei', StringParam)
  const [orgId, setOrgId] = useQueryParam('orgId', StringParam)

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })

  const { data, isLoading, refetch, isRefetching } = useAdminDevices(
    searchText,
    pagination.pageSize,
    pagination.pageIndex,
    orgId && orgId !== 'all' ? orgId : undefined,
  )

  const columns = useMemo<ColumnDef<AdminListDevice>[]>(() => {
    return [
      {
        id: 'actions',
        cell: ({ row }) => {
          const { id, imei, name } = row.original

          return (
            <div className="flex gap-2">
              <Button
                variant="outline"
                type="button"
                className="h-8 w-8 rounded-full border-yellow-700/50 p-0 text-yellow-700 hover:bg-yellow-50 hover:text-yellow-800"
                onClick={() => setDeviceId(id.toString())}
              >
                <span className="sr-only">Edit device</span>
                <Pencil2Icon className="h-4 w-4" />
              </Button>
              <DeleteDeviceButton id={id} imei={imei} name={name} />
            </div>
          )
        },
      },
      {
        accessorKey: 'imei',
        header: t('devices.imei'),
      },
      {
        accessorKey: 'name',
        header: t('devices.name'),
      },
      {
        accessorKey: 'organizationName',
        header: t('devices.organization_name'),
      },
      {
        accessorKey: 'type',
        header: t('devices.type'),
      },
      {
        accessorKey: 'notificationsEnabled',
        header: t('devices.notifications_enabled'),
      },
      {
        accessorKey: 'shouldUpdateViaFOTA',
        header: t('devices.should_update_via_fota'),
      },
      {
        accessorKey: 'eligibleForBetaFirmwareVersions',
        header: t('devices.eligible_for_beta_firmware_versions'),
      },
      {
        accessorKey: 'updatedAt',
        header: t('devices.updated_at'),
        cell: ({ row }) => {
          const { updatedAt } = row.original

          if (!updatedAt) return null

          return (
            <span className="whitespace-nowrap">
              {dateWithTimeInIsoFormat(new Date(updatedAt))}
            </span>
          )
        },
      },
    ]
  }, [])

  const table = useReactTable({
    data: data ? data.devices || [] : [],
    columns,
    pageCount: data?.totalCount
      ? Math.ceil(data.totalCount / pagination.pageSize)
      : -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <div>
      <div className="relative my-8">
        <h2 className="text-3xl">{t('Devices')}</h2>
        <div className="absolute right-0 top-0">
          <Button
            variant="outline"
            onClick={async () => refetch()}
            disabled={isRefetching}
          >
            <RefreshCw
              className={cn('h-4 w-4', isRefetching && 'animate-spin')}
            />
            <span className="pl-2 text-base">Refresh</span>
          </Button>
        </div>
      </div>
      <div className="my-4 flex gap-4">
        <Input
          className="max-w-xs"
          type="text"
          placeholder="Search by IMEI"
          defaultValue={searchText || ''}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div className="max-w-xs flex-1">
          <OrganizationNameAutocomplete
            value={orgId ?? undefined}
            onSelect={(orgId) => setOrgId(orgId)}
          />
        </div>
        <div className="flex flex-1 justify-end">
          <Button
            type="button"
            variant="outline"
            size="icon"
            onClick={() => setDeviceId('new')}
          >
            +
          </Button>
        </div>
      </div>
      <div>
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className="whitespace-nowrap">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {isLoading ? (
                Array.from({ length: 20 }).map((_, i) => (
                  <TableRow className="odd:bg-sky-50 hover:bg-sky-100" key={i}>
                    {table.getAllColumns().map((_, k) => (
                      <TableCell key={k}>
                        <Skeleton className="h-2 w-full" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    className="odd:bg-sky-50 hover:bg-sky-100"
                    key={row.id}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={table.getAllColumns().length}
                    className="h-24 text-center"
                  >
                    {t('data_table.no_results.text')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <DataTablePagination table={table} />
        <AddEditDeviceModal />
      </div>
    </div>
  )
}
