import { format, formatDistanceToNow, intlFormat, type Locale } from 'date-fns'
import { enUS, sv } from 'date-fns/locale'

export const dateWithTimeInIsoFormat = (date: Date) => {
  return format(date, 'yyyy-MM-dd HH:mm:ss')
}

export const dateWithShortTimeInIsoFormat = (date: Date) => {
  return format(date, 'yyyy-MM-dd HH:mm')
}

export const yearMonthAndDay = (date: Date) => {
  return format(date, 'yyyy-MM-dd')
}

export const monthAndDay = (date: Date) => {
  return format(date, 'LL dd')
}

export const hourAndMinute = (date: Date) => {
  return format(date, 'HH:mm')
}

export const localizedDateWithTime = (date: Date) => {
  return `${intlFormat(date, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })}`
}

export const shortLocalizedDateWithTime = (date: Date) => {
  return `${intlFormat(date, {
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })}`
}

export const veryShortLocalizedDateWithTime = (date: Date) => {
  return `${intlFormat(date, {
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })}`
}

export const localizedDateWithoutTime = (date: Date) => {
  return `${intlFormat(date, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  })}`
}

export const shortLocalizedDateWithoutTime = (date: Date) => {
  return `${intlFormat(date, {
    month: 'numeric',
    day: 'numeric',
  })}`
}

export const timeWithoutDate = (date: Date) => {
  return intlFormat(date, {
    hour: 'numeric',
    minute: 'numeric',
  })
}

export const localizedTimeSince = (date: Date, language?: string) => {
  return formatDistanceToNow(date, {
    addSuffix: true,
    locale: mapLanguageToLocale(language),
  })
}

const mapLanguageToLocale = (language?: string): Locale => {
  if (!language) return enUS

  switch (language) {
    case 'en':
      return enUS
    case 'sv':
      return sv

    default:
      return enUS
  }
}
