import { addMinutes, format, isAfter } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import { useMemo, useState } from 'react'
import type { DateRange } from 'react-day-picker'
import { DateTimeParam, useQueryParams, withDefault } from 'use-query-params'

import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { dateWithShortTimeInIsoFormat } from '@/utils/dateHelpers'

const shortTimeRangePresets = [
  { value: -30, label: 'Last 30 minutes' },
  { value: -60, label: 'Last hour' },
  { value: -120, label: 'Last 2 hours' },
  { value: -440, label: 'Last 4 hours' },
  { value: -720, label: 'Last 12 hours' },
  { value: -1440, label: 'Last day' },
]

const longTimeRangePresets = [
  { value: -1440, label: 'Last Day' },
  { value: -2880, label: 'Last 2 Days' },
  { value: -10080, label: 'Last Week' },
]

interface TableDateSelectProps {
  defaultFrom?: Date
  defaultTo?: Date
  useLongTimeRanges?: boolean
  hideTimeForFutureDates?: boolean
}

function TableDateSelect({
  defaultFrom,
  defaultTo,
  useLongTimeRanges = false,
  hideTimeForFutureDates = false,
}: TableDateSelectProps) {

  const [date, setDate] = useQueryParams({
    from: defaultFrom ? withDefault(DateTimeParam, defaultFrom) : DateTimeParam,
    to: defaultTo ? withDefault(DateTimeParam, defaultTo) : DateTimeParam,
  })

  const handleChangeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === 'from') {
      if (date?.from) {
        const d = date.from
        d.setHours(Number(e.target.value.split(':')[0]))
        d.setMinutes(Number(e.target.value.split(':')[1]))
        setDate({ from: d })
      }
    } else {
      if (date?.to) {
        const d = date.to
        d.setHours(Number(e.target.value.split(':')[0]))
        d.setMinutes(Number(e.target.value.split(':')[1]))
        setDate({ to: d })
      }
    }
  }

  const handleChangeDate = (d: Date) => {
    if (date.to || !date.from) {
      setDate({ from: d, to: undefined })
    } else {
      setDate((prev) => ({ ...prev, to: d }))
    }
  }

  const [selectValue, setSelectValue] = useState<number | null>(null)

  const presets = useMemo(() => {
    return useLongTimeRanges ? longTimeRangePresets : shortTimeRangePresets
  }, [useLongTimeRanges])

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn(
            'w-full justify-start text-left font-normal',
            !date && 'text-muted-foreground',
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date?.from && date?.to ? (
            <>
              {dateWithShortTimeInIsoFormat(new Date(date.from))} -{' '}
              {hideTimeForFutureDates && isAfter(new Date(date.to), new Date()) ? 'now' : dateWithShortTimeInIsoFormat(new Date(date.to))}
            </>
          ) : (
            <span>Pick a date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0" align="start">
        <div className="p-4">
          <label
            htmlFor="from"
            className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
          >
            Presets:
          </label>
          <Select
            value={selectValue?.toString()}
            onValueChange={(value) => {
              setDate({
                from: addMinutes(new Date(), Number(value)),
                to: new Date(),
              })
              setSelectValue(Number(value))
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select">
                {selectValue ? presets[selectValue]?.label : ''}
              </SelectValue>
            </SelectTrigger>
            <SelectContent position="popper">
              {presets.map((preset) => (
                <SelectItem key={preset.value} value={preset.value.toString()}>
                  {preset.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <Calendar
          mode="range"
          selected={date as DateRange}
          onDayClick={handleChangeDate}
          onSelect={(range) => {
            if (
              typeof range?.from === 'object' &&
              typeof range?.to === 'object'
            ) {
              setSelectValue(null)
              const rangeFrom = range.from
              const rangeTo = range.to

              setDate({
                from: rangeFrom,
                to: rangeTo,
              })
            }
          }}
          toDate={new Date()}
          initialFocus
        />
        <div className="p-4">
          <div className="flex justify-between gap-4">
            <div className="flex-1">
              <label
                htmlFor="from"
                className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
              >
                From:
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center pe-3.5">
                  <svg
                    className="h-4 w-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  type="time"
                  id="from"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm leading-none text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  value={date.from ? format(date.from, 'HH:mm') : '00:00'}
                  onChange={handleChangeTime}
                  required
                />
              </div>
            </div>
            <div className="flex-1">
              <label
                htmlFor="to"
                className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
              >
                To:
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center pe-3.5">
                  <svg
                    className="h-4 w-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  type="time"
                  id="to"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm leading-none text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  value={date.to ? format(date.to, 'HH:mm') : '23:59'}
                  onChange={handleChangeTime}
                  required
                />
              </div>
            </div>
          </div>
        </div>
        {date?.from && date?.to ? (
          <div className="p-4">
            <Button
              variant="outline"
              onClick={() => setDate({ from: undefined, to: undefined })}
            >
              Clear selection
            </Button>
          </div>
        ) : null}
      </PopoverContent>
    </Popover>
  )
}
export default TableDateSelect
