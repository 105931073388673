import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import type { UseFormReset } from 'react-hook-form'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import LocationIcon from '@/assets/icons/mappin.svg?react'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import FormSectionHeader from '@/components/v2/FormSectionHeader'
import type { AdminDetailedDevice } from '@/data/admin/device'
import type { DetailedDevice, DeviceType } from '@/data/device'
import useAdminOrganization from '@/hooks/useAdminOrganization'
import {
  DeviceSchema,
  getDeviceDefaultValuesInLocalUnit,
} from '@/utils/deviceHelpers'
import {
  getAreaUnitSuffix,
  getLongDistanceUnitSuffix,
  getShortDistanceUnitSuffix,
  getVolumeUnitSuffix,
} from '@/utils/unitHelpers'

import { Button } from '../ui/button'
import { Switch } from '../ui/switch'
import LocationMapDialog from '../v2/dialogs/LocationMapDialog'
import ImageSettings from '../v2/ImageSettings'
import InfoTooltip from '../v2/InfoTooltip'
import DeviceTypeSelect from '../v2/selects/DeviceTypeSelect'

import BasinSpecSelect from './BasinSpecSelect'
import OrganizationNameAutocomplete from './OrganizationNameAutocomplete'

const AdminDeviceSchema = DeviceSchema.extend({
  shouldUpdateViaFOTA: z.boolean(),
  eligibleForBetaFirmwareVersions: z.boolean(),
  slownessRatio: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number().min(0).max(1).optional().nullable(),
  ),
  sensorOffsetLeft: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number(),
  ),
  sensorOffsetRight: z.preprocess(
    (val) =>
      typeof val === 'string' ? parseFloat(z.string().parse(val)) : val,
    z.number(),
  ),
})

export type CreateUpdateAdminDevice = z.infer<typeof AdminDeviceSchema>

function AdminDeviceSettingsForm({
  device,
  onSubmit,
  mode = 'update',
  disabled,
}: {
  device?: Partial<AdminDetailedDevice>
  onSubmit: (
    data: CreateUpdateAdminDevice,
    reset: UseFormReset<CreateUpdateAdminDevice>,
  ) => Promise<void>
  mode?: 'create' | 'update'
  disabled?: boolean
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [isEditImeiEnabled, setIsEditImeiEnabled] = useState(false)
  const { t } = useTranslation(['app', 'common'])

  const form = useForm<CreateUpdateAdminDevice>({
    resolver: zodResolver(AdminDeviceSchema),
    defaultValues: {
      name: device?.name || '',
      type: device?.type || '',
      area: device?.area || undefined,
      lat: device?.lat,
      lng: device?.lng,
      coordinates: `${device?.lat || ''}, ${device?.lng || ''}`,
      imageUrl: device?.imageUrl || undefined,
      notificationsEnabled: device?.notificationsEnabled || true,
      imei: device?.imei || '',
      basinSpecId: device?.basinSpecId || undefined,
      usesDripLines: device?.usesDripLines || false,
      shouldUpdateViaFOTA: device?.shouldUpdateViaFOTA || false,
      eligibleForBetaFirmwareVersions:
        device?.eligibleForBetaFirmwareVersions || false,
      sensorOffsetLeft: device?.sensorOffsetLeft || 0,
      sensorOffsetRight: device?.sensorOffsetRight || 0,
      leftSectionAlias: device?.leftSectionAlias || '',
      rightSectionAlias: device?.rightSectionAlias || '',
      ...getDeviceDefaultValuesInLocalUnit(device?.distanceUnit || 'meter'),
    },
  })

  const deviceType = useWatch({ control: form.control, name: 'type' })
  const orgId = useWatch({ control: form.control, name: 'organizationId' })

  const { data: org } = useAdminOrganization(orgId?.toString())
  const distanceUnit = org?.distanceUnit || device?.distanceUnit || 'meter'

  const basinUnit = getShortDistanceUnitSuffix(distanceUnit)
  const installationUnit = getLongDistanceUnitSuffix(distanceUnit)
  const areaUnit = getAreaUnitSuffix(distanceUnit)
  const volumeUnit = `${getVolumeUnitSuffix(distanceUnit)}`

  useEffect(() => {
    if (device && !form.formState.isDirty) {
      form.reset({
        ...getDeviceDefaultValuesInLocalUnit(distanceUnit),
        ...device,
        shouldUpdateViaFOTA: device.shouldUpdateViaFOTA || false,
        eligibleForBetaFirmwareVersions:
          device.eligibleForBetaFirmwareVersions || false,
        slownessRatio: device.slownessRatio ?? null,
        sensorOffsetLeft: device?.sensorOffsetLeft || 0,
        sensorOffsetRight: device?.sensorOffsetRight || 0,
        coordinates:
          device.lat && device.lng ? `${device.lat}, ${device.lng}` : '',
        leftSectionAlias: device.leftSectionAlias || '',
        rightSectionAlias: device.rightSectionAlias || '',
      })
    }
  }, [device])

  useEffect(() => {
    if (org && form.formState.isDirty) {
      const existingValues = form.getValues()
      const defaultValues = getDeviceDefaultValuesInLocalUnit(org.distanceUnit)
      const lat = existingValues.lat | org.lat | 0
      const lng = existingValues.lng | org.lng | 0
      const coordinates = `${lat}, ${lng}`

      form.reset({
        ...existingValues,
        ...defaultValues,
        lat,
        lng,
        coordinates,
      })
    }
  }, [orgId, org])

  const handleSubmit = async (data: CreateUpdateAdminDevice) => {
    await onSubmit(data, form.reset)
  }

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <FormSectionHeader text={t('device_settings.settings_header')} />
          <div className="my-4 flex flex-col gap-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field, fieldState }) => (
                <FormItem className="flex-1">
                  <FormLabel>{t('device_settings.name')}</FormLabel>
                  <FormControl>
                    <Input
                      variant={fieldState.error ? 'error' : 'default'}
                      disabled={disabled}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="imei"
              render={({ field, fieldState }) => (
                <FormItem className="flex-1">
                  <FormLabel className="flex items-center gap-2">
                    {t('device_settings.imei')}
                    <Button
                      variant="link"
                      className="text-xs"
                      onClick={() => setIsEditImeiEnabled((prev) => !prev)}
                    >
                      {isEditImeiEnabled ? 'Click to Lock' : 'Click to Edit'}
                    </Button>
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      variant={fieldState.error ? 'error' : 'default'}
                      disabled={
                        (mode === 'create' && Boolean(device?.imei)) ||
                        disabled ||
                        !isEditImeiEnabled
                      }
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex flex-wrap gap-4 sm:flex-nowrap">
              <FormField
                control={form.control}
                name="leftSectionAlias"
                render={({ field, fieldState }) => (
                  <FormItem className="min-w-full flex-1 sm:min-w-fit">
                    <FormLabel className="flex items-center gap-2">
                      {t('device_settings.left_section_alias')}
                      <InfoTooltip
                        text={t('device_settings.left_section_alias_tooltip')}
                        position="right"
                      />
                    </FormLabel>
                    <FormControl>
                      <Input
                        disabled={disabled}
                        variant={fieldState.error ? 'error' : 'default'}
                        {...field}
                        value={field.value || ''}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="rightSectionAlias"
                render={({ field, fieldState }) => (
                  <FormItem className="min-w-full flex-1 sm:min-w-fit">
                    <FormLabel className="flex items-center gap-2">
                      {t('device_settings.right_section_alias')}
                      <InfoTooltip
                        text={t('device_settings.right_section_alias_tooltip')}
                        position="left"
                      />
                    </FormLabel>
                    <FormControl>
                      <Input
                        disabled={disabled}
                        variant={fieldState.error ? 'error' : 'default'}
                        {...field}
                        value={field.value || ''}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex gap-2">
              <FormField
                control={form.control}
                name="organizationId"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>{t('device_settings.organization')}</FormLabel>
                    <FormControl>
                      <OrganizationNameAutocomplete
                        value={field.value?.toString() ?? undefined}
                        onSelect={(val) => (val ? field.onChange(val) : null)}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="basinSpecId"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>{t('device_settings.basin_spec')}</FormLabel>
                    <FormControl>
                      <BasinSpecSelect
                        value={field.value?.toString() ?? undefined}
                        onChange={(val) => (val ? field.onChange(val) : null)}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>{t('device_settings.type')}</FormLabel>
                  <FormControl>
                    <DeviceTypeSelect
                      disabled={disabled}
                      onValueChange={(val) => field.onChange(val as DeviceType)}
                      value={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {deviceType !== 'bunker' ? (
              <>
                <div className="flex flex-wrap gap-4 sm:flex-nowrap">
                  <FormField
                    control={form.control}
                    name="materialDepth"
                    render={({ field, fieldState }) => (
                      <FormItem className="min-w-full flex-1 sm:min-w-fit">
                        <FormLabel className="flex items-center gap-2">
                          {t('device_settings.material_depth')}
                          {` (${basinUnit})`}
                          <InfoTooltip
                            text={t('device_settings.material_depth_tooltip')}
                            position="right"
                          />
                        </FormLabel>
                        <FormControl>
                          <Input
                            disabled={disabled}
                            variant={fieldState.error ? 'error' : 'default'}
                            type="number"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="gravelDepth"
                    render={({ field, fieldState }) => (
                      <FormItem className="min-w-full flex-1 sm:min-w-fit">
                        <FormLabel className="flex items-center gap-2">
                          {t('device_settings.gravel_depth')}
                          {` (${basinUnit})`}
                          <InfoTooltip
                            text={t('device_settings.gravel_depth_tooltip')}
                          />
                        </FormLabel>
                        <FormControl>
                          <Input
                            disabled={disabled}
                            variant={fieldState.error ? 'error' : 'default'}
                            type="number"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="sandDepth"
                    render={({ field, fieldState }) => (
                      <FormItem className="min-w-full flex-1 sm:min-w-fit">
                        <FormLabel className="flex items-center gap-2">
                          {t('device_settings.sand_depth')}
                          {` (${basinUnit})`}
                          <InfoTooltip
                            text={t('device_settings.sand_depth_tooltip')}
                            position="left"
                          />
                        </FormLabel>
                        <FormControl>
                          <Input
                            disabled={disabled}
                            variant={fieldState.error ? 'error' : 'default'}
                            type="number"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex flex-wrap gap-4 sm:flex-nowrap">
                  <FormField
                    control={form.control}
                    name="materialPorosity"
                    render={({ field, fieldState }) => (
                      <FormItem className="min-w-full flex-1 sm:min-w-fit">
                        <FormLabel className="flex items-center gap-2">
                          {t('device_settings.material_porosity')}
                          {` (%)`}
                          <InfoTooltip
                            text={t(
                              'device_settings.material_porosity_tooltip',
                            )}
                            position="right"
                          />
                        </FormLabel>
                        <FormControl>
                          <Input
                            disabled={disabled}
                            variant={fieldState.error ? 'error' : 'default'}
                            type="number"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="gravelPorosity"
                    render={({ field, fieldState }) => (
                      <FormItem className="min-w-full flex-1 sm:min-w-fit">
                        <FormLabel className="flex items-center gap-2">
                          {t('device_settings.gravel_porosity')}
                          {` (%)`}
                          <InfoTooltip
                            text={t('device_settings.gravel_porosity_tooltip')}
                          />
                        </FormLabel>
                        <FormControl>
                          <Input
                            disabled={disabled}
                            variant={fieldState.error ? 'error' : 'default'}
                            type="number"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="sandPorosity"
                    render={({ field, fieldState }) => (
                      <FormItem className="min-w-full flex-1 sm:min-w-fit">
                        <FormLabel className="flex items-center gap-2">
                          {t('device_settings.sand_porosity')}
                          {` (%)`}
                          <InfoTooltip
                            text={t('device_settings.sand_porosity_tooltip')}
                            position="left"
                          />
                        </FormLabel>
                        <FormControl>
                          <Input
                            disabled={disabled}
                            variant={fieldState.error ? 'error' : 'default'}
                            type="number"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex flex-wrap gap-4 sm:flex-nowrap">
                  <FormField
                    control={form.control}
                    name="desiredWaterLevel"
                    render={({ field, fieldState }) => (
                      <FormItem className="min-w-full flex-1 sm:min-w-fit">
                        <FormLabel className="flex items-center gap-2">
                          {t('device_settings.desired_water_level')}
                          {` (${basinUnit})`}
                          <InfoTooltip
                            text={t(
                              'device_settings.desired_water_level_tooltip',
                            )}
                            position="right"
                          />
                        </FormLabel>
                        <FormControl>
                          <Input
                            disabled={disabled}
                            variant={fieldState.error ? 'error' : 'default'}
                            type="number"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="materialHeight"
                    render={({ field, fieldState }) => (
                      <FormItem className="min-w-full flex-1 sm:min-w-fit">
                        <FormLabel className="flex items-center gap-2">
                          {t('device_settings.material_height')}
                          {` (${basinUnit})`}
                          <InfoTooltip
                            text={t('device_settings.material_height_tooltip')}
                            position="left"
                          />
                        </FormLabel>
                        <FormControl>
                          <Input
                            disabled={disabled}
                            variant={fieldState.error ? 'error' : 'default'}
                            type="number"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex flex-wrap gap-4 sm:flex-nowrap">
                  <FormField
                    control={form.control}
                    name="waterFlowRate"
                    render={({ field, fieldState }) => (
                      <FormItem className="min-w-full flex-1 sm:min-w-fit">
                        <FormLabel className="flex items-center gap-2">
                          {t('device_settings.water_flow_rate')}
                          {volumeUnit
                            ? ` (${volumeUnit}/${
                                volumeUnit === 'l' ? 's' : 'min'
                              })`
                            : ''}
                          <InfoTooltip
                            text={t('device_settings.water_flow_rate_tooltip')}
                            position="right"
                          />
                        </FormLabel>
                        <FormControl>
                          <Input
                            disabled={disabled}
                            variant={fieldState.error ? 'error' : 'default'}
                            type="number"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex flex-wrap gap-4 sm:flex-nowrap">
                  <FormField
                    control={form.control}
                    name="pipeDistance"
                    render={({ field, fieldState }) => (
                      <FormItem className="min-w-full flex-1 sm:min-w-fit">
                        <FormLabel className="flex items-center gap-2">
                          {t('device_settings.pipe_distance')}
                          {` (${installationUnit})`}
                          <InfoTooltip
                            text={t('device_settings.pipe_distance_tooltip')}
                            position="right"
                          />
                        </FormLabel>
                        <FormControl>
                          <Input
                            disabled={disabled}
                            variant={fieldState.error ? 'error' : 'default'}
                            type="number"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="pipeDiameter"
                    render={({ field, fieldState }) => (
                      <FormItem className="min-w-full flex-1 sm:min-w-fit">
                        <FormLabel className="flex items-center gap-2">
                          {t('device_settings.pipe_diameter')}
                          {` (${basinUnit})`}
                          <InfoTooltip
                            text={t('device_settings.pipe_diameter_tooltip')}
                            position="left"
                          />
                        </FormLabel>
                        <FormControl>
                          <Input
                            disabled={disabled}
                            variant={fieldState.error ? 'error' : 'default'}
                            type="number"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </>
            ) : null}
            <FormField
              control={form.control}
              name="area"
              render={({ field, fieldState }) => (
                <FormItem className="min-w-full flex-1 sm:min-w-fit">
                  <FormLabel className="flex items-center gap-2">
                    {t('device_settings.area')}
                    {` (${areaUnit})`}
                    <InfoTooltip
                      text={t('device_settings.area_tooltip')}
                      position="right"
                    />
                  </FormLabel>
                  <FormControl>
                    <Input
                      disabled={disabled}
                      variant={fieldState.error ? 'error' : 'default'}
                      type="number"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="coordinates"
              render={({ field, fieldState }) => (
                <FormItem className="flex-1">
                  <FormLabel>{t('device_settings.location')}</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Input
                        variant={fieldState.error ? 'error' : 'default'}
                        className="hover:cursor-pointer"
                        disabled={disabled}
                        onFocus={() => setIsOpen(true)}
                        {...field}
                      />
                      <Button
                        variant="anonymous"
                        size="icon"
                        className="absolute right-0 top-0"
                        disabled={disabled}
                        onClick={() => setIsOpen(true)}
                      >
                        <LocationIcon className="opacity-80" />
                      </Button>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="imageUrl"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>{t('device_settings.image')}</FormLabel>
                  <FormControl>
                    <ImageSettings
                      entity="device"
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      disabled={disabled}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>

          <div className="my-8 flex flex-col gap-4">
            <FormSectionHeader
              text={t('device_settings.calibrate_device', { ns: 'app' })}
            />
            <div className="my-4 flex flex-col gap-4">
              <FormField
                control={form.control}
                name="slownessRatio"
                render={({ field, fieldState }) => (
                  <FormItem className="min-w-full flex-1 sm:min-w-fit">
                    <FormLabel className="flex items-center gap-2">
                      {t('device_settings.slowness_ratio')}
                      <InfoTooltip
                        text={t('device_settings.slowness_ratio_tooltip')}
                      />
                    </FormLabel>
                    <FormControl>
                      <Input
                        disabled={disabled}
                        variant={fieldState.error ? 'error' : 'default'}
                        type="number"
                        {...field}
                        value={field.value || ''}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex flex-wrap gap-4 sm:flex-nowrap">
                <FormField
                  control={form.control}
                  name="sensorOffsetLeft"
                  render={({ field, fieldState }) => (
                    <FormItem className="min-w-full flex-1 sm:min-w-fit">
                      <FormLabel className="flex items-center gap-2">
                        {t('device_settings.sensor_offset_left')}
                        {` (${basinUnit})`}
                        <InfoTooltip
                          text={t('device_settings.sensor_offset_left_tooltip')}
                          position="right"
                        />
                      </FormLabel>
                      <FormControl>
                        <Input
                          disabled={disabled}
                          variant={fieldState.error ? 'error' : 'default'}
                          type="number"
                          {...field}
                          value={field.value || ''}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="sensorOffsetRight"
                  render={({ field, fieldState }) => (
                    <FormItem className="min-w-full flex-1 sm:min-w-fit">
                      <FormLabel className="flex items-center gap-2">
                        {t('device_settings.sensor_offset_right')}
                        {` (${basinUnit})`}
                        <InfoTooltip
                          text={t(
                            'device_settings.sensor_offset_right_tooltip',
                          )}
                          position="left"
                        />
                      </FormLabel>
                      <FormControl>
                        <Input
                          disabled={disabled}
                          variant={fieldState.error ? 'error' : 'default'}
                          type="number"
                          {...field}
                          value={field.value || ''}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>

          {deviceType === 'equestrian_sand' ? (
            <div className="my-8 flex flex-col gap-4">
              <FormSectionHeader
                text={t('device_settings.drip_lines_header', { ns: 'app' })}
              />
              <div className="my-4 flex flex-col gap-4">
                <FormField
                  control={form.control}
                  name="usesDripLines"
                  render={({ field }) => (
                    <FormItem className="min-w-full flex-1 sm:min-w-fit">
                      <FormLabel className="flex items-center gap-2">
                        {t('device_settings.use_driplines')}
                        <InfoTooltip
                          text={t('device_settings.use_driplines_tooltip')}
                          position="right"
                        />
                      </FormLabel>
                      <FormControl>
                        <Switch
                          disabled={disabled}
                          checked={field.value || false}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          ) : null}
          {deviceType !== 'equestrian_sand' ? (
            <div className="my-8 flex flex-col gap-4">
              <FormSectionHeader
                text={t('device_settings.fertigation_header', { ns: 'app' })}
              />
              <div className="my-4 flex flex-col gap-4">
                <FormField
                  control={form.control}
                  name="desiredNitrogenPPM"
                  render={({ field, fieldState }) => (
                    <FormItem className="min-w-full flex-1 sm:min-w-fit">
                      <FormLabel className="flex items-center gap-2">
                        {t('device_settings.desired_nitrogen_ppm')}
                        <InfoTooltip
                          text={t(
                            'device_settings.desired_nitrogen_ppm_tooltip',
                          )}
                          position="right"
                        />
                      </FormLabel>
                      <FormControl>
                        <Input
                          disabled={disabled}
                          variant={fieldState.error ? 'error' : 'default'}
                          type="number"
                          {...field}
                          value={field.value || ''}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="stockSolutionNitrogenAmount"
                  render={({ field, fieldState }) => (
                    <FormItem className="min-w-full flex-1 sm:min-w-fit">
                      <FormLabel className="flex items-center gap-2">
                        {t('device_settings.stock_solution_nitrogen_amount')}
                        {` (g/l)`}
                        <InfoTooltip
                          text={t(
                            'device_settings.stock_solution_nitrogen_amount_tooltip',
                          )}
                          position="right"
                        />
                      </FormLabel>
                      <FormControl>
                        <Input
                          disabled={disabled}
                          variant={fieldState.error ? 'error' : 'default'}
                          type="number"
                          {...field}
                          value={field.value || ''}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="fertigationFlowRate"
                  render={({ field, fieldState }) => (
                    <FormItem className="min-w-full flex-1 sm:min-w-fit">
                      <FormLabel className="flex items-center gap-2">
                        {t('device_settings.fertigation_flow_rate')}
                        {volumeUnit
                          ? ` (${volumeUnit}/${
                              volumeUnit === 'l' ? 's' : 'min'
                            })`
                          : ''}
                        <InfoTooltip
                          text={t(
                            'device_settings.fertigation_flow_rate_tooltip',
                          )}
                          position="right"
                        />
                      </FormLabel>
                      <FormControl>
                        <Input
                          disabled={disabled}
                          variant={fieldState.error ? 'error' : 'default'}
                          type="number"
                          {...field}
                          value={field.value || ''}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          ) : null}

          <div className="my-8 flex flex-col gap-4">
            <FormSectionHeader
              text={t('notifications.header', { ns: 'common' })}
            />
            <div className="my-4 flex flex-col gap-4">
              <FormField
                control={form.control}
                name="notificationsEnabled"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between border-b pb-4">
                    <FormLabel className="text-base">
                      {t('notifications.notifications_enabled', {
                        ns: 'common',
                      })}
                    </FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        disabled={disabled}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="my-8 flex flex-col gap-4">
            <FormSectionHeader text={t('device_settings.fota_header')} />
            <div className="my-4 flex flex-col gap-4">
              <FormField
                control={form.control}
                name="shouldUpdateViaFOTA"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between border-b pb-4">
                    <FormLabel className="text-base">
                      {t('device_settings.should_update_via_fota')}
                    </FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        disabled={disabled}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
            <div className="my-4 flex flex-col gap-4">
              <FormField
                control={form.control}
                name="eligibleForBetaFirmwareVersions"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between border-b pb-4">
                    <FormLabel className="text-base">
                      {t('device_settings.eligible_for_beta_firmware_versions')}
                    </FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        disabled={
                          disabled || !org?.eligibleForBetaFirmwareVersions
                        }
                        title={
                          !org?.eligibleForBetaFirmwareVersions
                            ? 'The organization is not eligible for Beta firmware versions'
                            : ''
                        }
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="my-8 flex justify-end gap-4">
            <Button
              variant="default"
              size="default"
              type="submit"
              className="font-semibold"
              isLoading={form.formState.isSubmitting}
            >
              {mode === 'update'
                ? t('device_settings.save')
                : t('device_settings.add')}
            </Button>
          </div>
        </form>
      </Form>
      <LocationMapDialog
        device={form.getValues() as unknown as DetailedDevice}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        showGetCurrent
        onSave={(lat, lng) => {
          if (lat && lng) {
            form.setValue('lat', lat)
            form.setValue('lng', lng)
            form.setValue('coordinates', `${lat}, ${lng}`)
            setIsOpen(false)
          }
        }}
      />
    </>
  )
}
export default AdminDeviceSettingsForm
