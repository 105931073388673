import * as Sentry from '@sentry/react'

import { axiosClient } from '@/lib/axios'

export interface ChartData {
  averageWaterLevelLeft: number
  averageWaterLevelRight: number
  accumulatedFillValveTime: number
  averageWaterLevelPercentage: number
  averageEcLeft?: number
  averageEcRight?: number
  averageTemperatureLeft?: number
  averageTemperatureRight?: number
  averageMoistureLeft?: number
  averageMoistureRight?: number
  timestamp: string
  samples: number
}

export interface RawData {
  timestamp: string
  waterLevelLeft: number
  waterLevelRight: number
  fillValveTime: number
  waterLevelPercentage: number
  ecLeft?: number
  ecRight?: number
  temperatureLeft?: number
  temperatureRight?: number
  moistureLeft?: number
  moistureRight?: number
}

export interface DeviceData {
  chartData: ChartData[]
  rawData: RawData[]
  hasMoistureSensor: boolean
}

const getBaseUri = (organizationId: string) =>
  `/organization/${organizationId}/devicedata/device`

export const getDeviceData = async (
  organizationId: string,
  id: string,
  fromDate?: Date,
  toDate?: Date,
  abortSignal?: AbortSignal,
): Promise<DeviceData> => {
  try {
    const searchParams = new URLSearchParams({
      fromDate: fromDate?.toISOString() ?? '', //Not sure about this.. ALWAYS timezones.. lets check
      toDate: toDate?.toISOString() ?? '',
    })

    const res = await axiosClient.get(
      `${getBaseUri(organizationId)}/${id}?${searchParams.toString()}`,
      { signal: abortSignal },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err, { extra: { deviceId: id, fromDate, toDate } })
    throw err
  }
}
